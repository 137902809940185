import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import About from "./pages/about/About";
import Anahess from "./pages/anahess/anahess";
import Method from "./pages/anahess/subpages/method/Method";
import Software from "./pages/anahess/subpages/software/Software";
import Contact from "./pages/contact/Contact";
import Index from "./pages/index/Index";

function App() {
  const routes: {
    name: string;
    path: string;
    element: JSX.Element;
    subelements?: { name: string; path: string; element: JSX.Element }[];
  }[] = [
    { name: "About", path: "/about", element: <About /> },
    {
      name: "Anahess",
      path: "/anahess",
      element: <Anahess />,
      subelements: [
        { name: "Method", path: "/anahess/method", element: <Method /> },
        { name: "Software", path: "/anahess/software", element: <Software /> },
      ],
    },
    { name: "Contact", path: "/contact", element: <Contact /> },
  ];
  const allRoutes: { name: string; path: string; element: JSX.Element }[] = [
    { name: "Home", path: "/", element: <Index /> },
    { name: "AnahessPage", path: "/anahess/:doc", element: <Anahess /> },

    ...routes,
  ];
  return (
    <BrowserRouter>
      <Navbar options={routes} />
      <div id="content">
        <Routes>
          {allRoutes.map((route) => {
            return (
              <Route
                path={route.path}
                element={route.element}
                key={route.name}
              />
            );
          })}
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
