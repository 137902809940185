import React from "react";
import { Link } from "react-router-dom";
import styles from "./software.module.css";
import Collapsible from "../../../../components/collapsible/Collapsible";
import Discrete1D from "./instructions/Discrete1D";
import Discrete2D from "./instructions/Discrete2D";
import Distribution1D from "./instructions/Distribution1D";
import Distribution2D from "./instructions/Distribution2D";
import SoftwarePDF from "../../../../media/articles/Anahess_software_jan_2023.pdf";

const Software: React.FC = () => {
  return (
    <div id={styles.software}>
      <div id={styles.title}>Software</div>
      <div className={styles.line} />
      <div className={styles.text}>
        Anvendt Teknologi AS has finalized a software that provides a data
        analysis package containing the Anahess discrete, Anahess distribution
        and ordinary Inverse Laplace for both one- and two-dimensional data
        sets. In the following the four applications will be described:
        <br />
      </div>
      <div className={styles.line} />
      <a href={SoftwarePDF} target={"blank"}>
        Click here to download the PDF file.
      </a>
      <object data={SoftwarePDF} type="application/pdf">
        <p>It appears you don't have a PDF plugin for this browser.</p>
      </object>
      <div className={styles.text}>
        For software inquiries please <Link to={"/contact"}>contact us</Link>
      </div>
    </div>
  );
};

export default Software;
