import React from "react";
import styles from "./navbar.module.css";
import { Link } from "react-router-dom";

export interface navOptions {
  options: navOption[];
}

interface navOption {
  name: string;
  path: string;
  subelements?: { name: string; path: string }[];
}

const Navbar: React.FC<navOptions> = (props: navOptions) => {
  const toggleNav = () => {
    const navOptions = document.getElementById(styles.navOptions);
    if (navOptions && window.innerWidth < 1024) {
      navOptions.classList.toggle(styles.transformed);
    }
    const hoverableOption = document.getElementsByClassName(
      styles.hoverableOption
    )[0] as HTMLElement;
    if (hoverableOption) {
      hoverableOption.classList.remove(styles.displayed);
    }
  };

  const toggleHover = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>
  ) => {
    const hoverableOption = e.currentTarget.getElementsByClassName(
      styles.hoverableOption
    )[0] as HTMLElement;
    hoverableOption.classList.toggle(styles.displayed);
  };

  return (
    <div id={styles.navbar}>
      <div id={styles.background} />
      <div id={styles.content}>
        <Link to="/">
          <img
            id={styles.logo}
            src={require("../../media/images/logos/AnTekWhite.png")}
            alt={"Anvendt Teknologi AS"}
          />
        </Link>
        <div id={styles.navOptions} className={styles.transformed}>
          {props.options.map((option) => {
            return option.subelements ? (
              <div
                key={option.name}
                className={styles.navOption}
                onClick={toggleHover}
              >
                {option.name}
                {option.subelements ? (
                  <div className={styles.hoverableOption}>
                    {option.subelements.map((subelement) => {
                      return (
                        <Link
                          to={subelement.path}
                          key={subelement.name}
                          onClick={(e) => {
                            toggleNav();
                            toggleHover(e);
                          }}
                        >
                          {subelement.name}
                        </Link>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <Link
                to={option.path}
                key={option.name}
                onClick={toggleNav}
                className={styles.navOption}
              >
                {option.name}
              </Link>
            );
          })}
        </div>
        <div id={styles.burgerNav} onClick={toggleNav}>
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
