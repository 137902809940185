import React from "react";
import { useParams } from "react-router-dom";
import Method from "./subpages/method/Method";
import Software from "./subpages/software/Software";
import styles from "./anahess.module.css";

const Anahess: React.FC = () => {
  const { doc } = useParams();

  return (
    <div id={styles.about}>{doc === "method" ? <Method /> : <Software />}</div>
  );
};

export default Anahess;
