import React from "react";
import Thumbnail from "../../components/thumbnails/Thumbnail";
import styles from "./index.module.css";
import innovationLogo from "../../media/images/logos/innovation_white.png";
import softwareLogo from "../../media/images/logos/software_white.png";
import researchLogo from "../../media/images/logos/research_white.png";

const Index: React.FC = () => {
  return (
    <div id={styles.index}>
      <div id={styles.decoContainer}>
        <div id={styles.topDecoration} />
        <div id={styles.secondaryTopDecoration} />
      </div>
      <div>
        <div id={styles.title}>Anvendt Teknologi AS</div>
        <div id={styles.subTitle}>NRM innovations since 1998</div>
      </div>
      <div id={styles.slideshow}>
        <Thumbnail
          title="The Anahess Method"
          text={
            "We have developed new method for analysing exponentially decaying data, called the Anahess distribution."
          }
          imgUrl={researchLogo}
          readMoreLink="/anahess/method"
        />
        <Thumbnail
          title={"Anahess Software"}
          text={
            "We have finalised a software package that provides Anahess discrete, Inverse Laplace Transform and its combination."
          }
          imgUrl={softwareLogo}
          readMoreLink="/anahess/software"
        />
        <Thumbnail
          title={"NRM Innovation"}
          text={
            "Anvendt Teknologi AS has since the foundation in 1998 been working on an application development for various purposes."
          }
          imgUrl={innovationLogo}
          readMoreLink="/about"
        />
      </div>
    </div>
  );
};

export default Index;
