import React from "react";
import { Link } from "react-router-dom";
import styles from "./aboutcontent.module.css";

const AboutUs: React.FC = () => {
  return (
    <div id={styles.about}>
      <div id={styles.title}>About us</div>
      <div className={styles.line} />

      <div className={styles.text}>
        <div id={styles.image_container}>
          <img
            src={require("../../../media/images/machine.png")}
            alt={"Hardware produced by us"}
            id={styles.machine_image}
          />
          Antek hardware used for NRM analysis
        </div>
        Anvendt Teknologi AS has since the foundation in 1998 been working with
        application development on areas related to Dynamic NMR, measurements of
        relaxation times and diffusion. The past two decades we have supplied
        NMR instrumentation (permanent magnets) and applications for various
        purposes; fat content in biological material, characterization of porous
        media (as rock core plugs) and emulsions (droplet size distributions and
        1-dimensional profiles). We have also offered consultancies and
        tailor-made applications and instrumentation. In addition to providing
        the services mentioned above we have been working persistently on a
        robust alternative to the ill-posed Inverse Laplace Transform, and after
        20 years of research and development we finally have a robust and unique
        alternative for analyzing one- and two-dimensional dynamic NMR data. In
        this work we have had the pleasure of receiving financial support from
        Innovation Norway. We recently released the anahess software, which you
        can <Link to={"/anahess"}>read about here</Link>.
      </div>
    </div>
  );
};

export default AboutUs;
