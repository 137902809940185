import React from "react";
import Analysis from "../../../../media/articles/On_the_Analysis.pdf";
import styles from "./method.module.css";

const Method: React.FC = () => {
  return (
    <div id={styles.about}>
      <div id={styles.title}>Method</div>
      <div className={styles.line} />
      <div className={styles.text}>
        Anvendt Teknologi AS has developed new method for analysing
        exponentially decaying data, called the Anahess distribution. It
        combines a discrete method (Anahess) and a quasi-continuous method
        (Inverse Laplace Transform) for analysing one- and two-dimensional
        multi-exponentially decaying as NMR data. While the Inverse Laplace
        Transform (ILT) algorithm generates solutions from one- and
        two-dimensional data sets where the solutions are generally ill-posed
        resulting in an infinite number of possible solutions, the Anahess
        represents a discrete method that minimizes the number of components
        that is sufficient to fit the data sets properly and limits the number
        of solutions to a single and unique one. By combining these two
        approaches we have developed a method that is more robust and probes the
        distributivity of the exponential decays. The performance has been
        proven on synthetic data sets where we know that solution as well as on
        real data sets. The method has been published in the Journal of Applied
        Magnetic Resonance
      </div>
      <div className={styles.line} />
      <a href={Analysis} target={"blank"}>
        Click here to download the PDF file.
      </a>
      <object data={Analysis} type="application/pdf">
        <p>It appears you don't have a PDF plugin for this browser.</p>
      </object>
    </div>
  );
};

export default Method;
