import React from "react";
import emailjs from "@emailjs/browser";
import styles from "./contact.module.css";

const Contact: React.FC = () => {
  const [disabledButton, setDisabledButton] = React.useState(false);

  const sendEmail = (e: any) => {
    e.preventDefault();
    setDisabledButton(true);
    document.getElementById(styles.verification)!.style.display = "none";

    for (let i = 0; i < e.target.length; i++) {
      if (e.target[i].value === "") {
        alert("Please fill in all fields");
        setDisabledButton(false);
        return;
      }
    }

    emailjs
      .sendForm(
        "service_k6co3ra",
        "template_apw0j1t",
        e.target,
        "UCTxMDkP37rFW3kku"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.user_name.value = "";
          e.target.user_email.value = "";
          e.target.message.value = "";
          setDisabledButton(false);
          document.getElementById(styles.verification)!.style.display = "block";
        },
        (error) => {
          alert(
            "Could not send your message due to the following error:\n " +
              error.text +
              "\nPlease try again or contact us at geirhs@antek.no if the problem persists."
          );
          setDisabledButton(false);
        }
      );
  };

  return (
    <div id={styles.content}>
      <div id={styles.contact_info}>
        <h3>Contact Us</h3>
        Geir Humborstad Sørland <br />
        CEO, Anvendt Teknologi AS <br />
        geirhs@antek.no
      </div>
      <form onSubmit={sendEmail} id={styles.form}>
        <div>
          <input type="text" name="user_name" placeholder="Your name" />
          <input
            type="email"
            name="user_email"
            placeholder="example@email.com"
          />
        </div>
        <textarea name="message" placeholder="Message..." />
        <div id={styles.verification}>Email has been sent!</div>
        <input
          type="submit"
          value="Send"
          id={styles.send_button}
          disabled={disabledButton}
        />
      </form>
    </div>
  );
};

export default Contact;
