import React from "react";
import AboutUs from "./subpages/AboutUs";

export const articles = [
  {
    name: "A robust method for Analysing One and Two-Dimensional Dynamic NMR Data",
    shorthand: "analysis",
    path: "/about/articles/analysis",
  },
  {
    name: "Fat content NRM dried",
    shorthand: "fat_content",
    path: "/about/articles/fat_content",
  },
  {
    name: "Characterisation of emulsions",
    shorthand: "emulsion",
    path: "/about/articles/emulsion",
  },
  {
    name: "A spoiler recovery method for rapid diffusion measurements",
    shorthand: "recovery",
    path: "/about/articles/recovery",
  },
];

const About: React.FC = () => {
  return <AboutUs />;
};

export default About;
