import React from "react";
import { Link } from "react-router-dom";
import Circle from "../circles/Circle";
import styles from "./thumbnail.module.css";

const Thumbnail: React.FC<{
  title: string;
  text: string;
  imgUrl: string;
  readMoreLink: string;
}> = (props: {
  text: string;
  title: string;
  imgUrl: string;
  readMoreLink: string;
}) => {
  return (
    <Link to={props.readMoreLink}>
      <div id={styles.thumbnail}>
        <Circle imgUrl={props.imgUrl} classname={styles.circle} />
        <div id={styles.title}>{props.title}</div>
        <div id={styles.text}>{props.text} </div>
        <div id={styles.linkContainer}>Read more -{">"}</div>{" "}
      </div>
    </Link>
  );
};

export default Thumbnail;
