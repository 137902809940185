import React from "react";
import styles from "./circle.module.css";

interface circleprops {
  imgUrl: string;
  classname?: string;
}

const Circle: React.FC<circleprops> = (props: circleprops) => {
  return (
    <div id={styles.circle} className={props.classname}>
      <img src={props.imgUrl} alt=""></img>
    </div>
  );
};

export default Circle;
