import React from "react";
import styles from "./footer.module.css";

const Footer: React.FC = () => {
  return (
    <div id={styles.footer}>
      <div id={styles.footer_content}>© 2022 Anvendt Teknologi AS</div>
    </div>
  );
};

export default Footer;
